import React from 'react';
import StoreLayout from '../components/StoreLayout/StoreLayout';
import SEO from '../components/SEO/SEO';

const Home = ({ pageContext }) => {
  const { title, poster } = pageContext;

  return (
    <StoreLayout page={title.toLowerCase()} poster={poster}>
      <SEO
        title={title}
        description="Everything about Wibi Soerjadi, his history, life path and his amazing sheet music is available right here."
        keywords={[`wibi`, `soerjadi`, `wibi soerjadi`, `about`, `masterclass`, `summercourse`, `academy`, `sheet`, `music`, `sheet music`, `shop`]}
      />
    </StoreLayout>
  )
};

export default Home;